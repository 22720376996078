<script setup lang="ts">
import { ref } from 'vue';
import { generateSocialLink } from '../../shared/helpers/social';
import donateImg from '../../assets/donation.png'
import { event } from 'vue-gtag';
const expanded = ref(true)
const recordEvent = (eventToRecord: string) => {
    event(eventToRecord)
}
</script>
<template>
    <div class="flex items-center justify-center gap-0 bg-pink-400 rounded-lg rounded-e-none fixed right-0 top-[50%] z-30 select-none">
        <div class="pointer" :class="!expanded ? 'py-10 px-2': ''">
            <svg @click="expanded = true" v-if="!expanded" class="h-8 w-8 text-white"  width="24" height="24" viewBox="0 0 24 24" stroke-width="2" stroke="currentColor" fill="none" stroke-linecap="round" stroke-linejoin="round">  <path stroke="none" d="M0 0h24v24H0z"/>  <path d="M18 15l-6-6l-6 6h12" transform="rotate(270 12 12)" /></svg>
            <svg @click="expanded = false" v-else class="h-8 w-8 text-white"  width="24" height="24" viewBox="0 0 24 24" stroke-width="2" stroke="currentColor" fill="none" stroke-linecap="round" stroke-linejoin="round">  <path stroke="none" d="M0 0h24v24H0z"/>  <path d="M18 15l-6-6l-6 6h12" transform="rotate(90 12 12)" /></svg>
        </div>
        <div :class="!expanded ? 'hidden': 'py-5'"
            class="flex flex-col items-center jusitfy-center">
            <p class="p-2 pb-5 pe-5 font-bold text-white">Like & Support</p>
            <ul class="list-unstyled flex flex-col p-5 ps-0 pt-0 gap-7">
                <li @click="recordEvent('side_social_clicked_donate')">
                    <router-link to="donate" aria-label="Donate">
                        <img class="hover:shadow-2xl" :src="donateImg" alt="Twitter X profile" width="40px" height="40px"
                            style="display: inline-block; vertical-align: baseline;" />
                    </router-link>
                </li>
                <li @click="recordEvent('side_social_clicked_facebook')">
                    <a target="_blank" aria-label="Visit our Facebook page"
                        :href="generateSocialLink('https://www.facebook.com/AudioAIDynamicsPage', 'fb://profile/AudioAIDynamicsPage', 'intent://www.facebook.com/AudioAIDynamicsPage#Intent;package=com.facebook.katana;scheme=https;end')">
                        <img class="hover:shadow-2xl" src="/facebook-icon.svg" alt="Facebook profile" width="30px" height="30px"
                            style="display: inline-block; vertical-align: baseline; height: 30px;" />
                    </a>
                </li>
                <li @click="recordEvent('side_social_clicked_instagram')">
                    <a target="_blank" aria-label="Visit our Instagram profile"
                        :href="generateSocialLink('https://www.instagram.com/audioaidynamics', 'instagram://user?username=audioaidynamics', 'intent://www.instagram.com/audioaidynamics#Intent;package=com.instagram.android;scheme=https;end')">
                        <img class="hover:shadow-2xl" src="/instagram-icon.svg" alt="Instagram profile" width="30px" height="30px"
                            style="display: inline-block; vertical-align: baseline; height: 30px;" />
                    </a>
                </li>
                <li @click="recordEvent('side_social_clicked_X')">
                    <a target="_blank" aria-label="Visit our X profile"
                        :href="generateSocialLink('https://twitter.com/audio_dynamics_', 'twitter://user?screen_name=audio_dynamics_', 'intent://twitter.com/audio_dynamics_#Intent;package=com.twitter.android;scheme=https;end')">
                        <img class="hover:shadow-2xl" src="/twitter-x-icon.svg" alt="Twitter X profile" width="30px" height="30px"
                            style="display: inline-block; vertical-align: baseline; height: 30px;" />
                    </a>
                </li>
            </ul>
        </div>
    </div>
</template>