import baseEntityService from '../services/baseEntityService'

export default class RegisterService extends baseEntityService<any> {
  private static instance: RegisterService

  private constructor() {
    super('/auth/register')
  }

  public static getInstance(): RegisterService {
    if (!RegisterService.instance)
      return new RegisterService()

    return RegisterService.instance
  }
}
