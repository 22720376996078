import type { IRole } from './RoleModel'

export const enum Gender {
  MALE = 'MALE',
  FEMALE = 'FEMALE',
  OTHER = 'OTHER',
}
export interface IUser {
  readonly id?: number
  readonly createdOn?: Date
  readonly updatedOn?: Date
  readonly createdBy?: number
  readonly updatedBy?: number
  readonly uid?: string
  email: string
  username: string
  password?: string
  firstName?: string
  gender?: Gender
  lastName?: string
  birthDate?: Date
  enabled?: boolean
  subscribed?: boolean
  roles?: IRole[]
  avatarImage?: string
  avatarUrl?: string
  languageKey?: string
}

export default class User implements IUser {
  constructor(
    public email: string,
    public username: string,
    public readonly id?: number,
    public readonly createdOn?: Date,
    public readonly updatedOn?: Date,
    public readonly createdBy?: number,
    public readonly updatedBy?: number,
    public readonly uid?: string,
    public password?: string,
    public firstName?: string,
    public lastName?: string,
    public birthDate?: Date,
    public gender?: Gender,
    public enabled?: boolean,
    public subscribed?: boolean,
    public roles?: IRole[],
    public avatarImage?: string,
    public avatarUrl?: string,
    public languageKey?: string,
  ) {
    this.email = ''
    this.username = ''
  }
}
