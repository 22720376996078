<script setup lang="ts">
interface IProps {
    width?: number
    height?: number
    color?:string
    darkColor?:string
}
const props = defineProps<IProps>()
</script>
<template>
    <svg :class="[props.width ? props.width : 'w-4',props.height ? props.height : 'h-4', props.color ? props.color : 'text-gray-500', props.darkColor ? props.darkColor : 'text-gray-400']" aria-hidden="true" xmlns="http://www.w3.org/2000/svg"
        fill="none"  stroke="currentColor" viewBox="0 0 24 24">
        <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2"
                d="M13.875 18.825A10.05 10.05 0 0112 19c-4.478 0-8.268-2.943-9.543-7a9.97 9.97 0 011.563-3.029m5.858.908a3 3 0 114.243 4.243M9.878 9.878l4.242 4.242M9.88 9.88l-3.29-3.29m7.532 7.532l3.29 3.29M3 3l3.59 3.59m0 0A9.953 9.953 0 0112 5c4.478 0 8.268 2.943 9.543 7a10.025 10.025 0 01-4.132 5.411m0 0L21 21" />
    </svg>
</template>