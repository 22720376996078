<script setup lang="ts">
import { ref } from 'vue'
import Popup from './aaid/Popup.vue';
import useLoginStore from '../stores/useLoginStore';
import { AxiosResponse } from 'axios';
import Spinner from './aaid/Spinner.vue'
import ForgotPasswordService from '../shared/services/ForgotPasswordService';
import useToastMessageStore from '../stores/useToastMessageStore';
import AaidInput from './aaid/AaidInput.vue';
import MailIcon from './aaid/svgIcons/MailIcon.vue';

const username = ref('')
const isLoading = ref(false)
const errors: any = ref([])
const forgotPasswordService = ForgotPasswordService.getInstance()
const loginStore = useLoginStore()
const toastMessageStore = useToastMessageStore()
const onModalClose = () => {
  loginStore.setShowForgotPassModal(false)
}
const handleReset = (e: any) => {
  e.preventDefault();
  isLoading.value = true
  forgotPasswordService.post({ value: username.value }).then(async (resp: AxiosResponse) => {
    isLoading.value = false
    if (resp && resp.data) {
        toastMessageStore.setMessage('Sucesfully sent, please check your email')
        toastMessageStore.setTitle('Forgot password')
        toastMessageStore.setType('success')
        toastMessageStore.setVisible(true)
    } else {
        toastMessageStore.setMessage('Something went wrong')
        toastMessageStore.setTitle('Error')
        toastMessageStore.setType('alert')
        toastMessageStore.setVisible(true)
    }
  }).catch(e => {
    if (e.response.data.detail) {
      errors.value.push(e.response.data.detail)
    } else if (e.response.data.violations) {
      const newErrors = e.response.data.violations.map((z: any) => `${z.field} ${z.message}`)
      errors.value = newErrors
    } else if (e.response.data.error) {
      errors.value.push(e.response.data.error)
    }
    isLoading.value = false
    initClearErrors()
  })
}
const initClearErrors = () => {
  setTimeout(() => {
    errors.value = []
  }, 5000)
}
const showRegisterModal = () => {
  loginStore.setShowLoginModal(false)
  loginStore.setShowForgotPassModal(false)
  loginStore.setShowRegisterModal(true)
}
const showLoginModal = () => {
  loginStore.setShowLoginModal(true)
  loginStore.setShowForgotPassModal(false)
  loginStore.setShowRegisterModal(false)
}

const closeErrorMsg = (ind: any) => {
  errors.value.splice(ind, 1)
}
</script>
<template>
  <Popup v-if="loginStore.getShowForgotPassModal" @on-close="onModalClose">
    <template #header>
      <span class="text-2xl font-bold w-full text-center mb-4 mt-4">FORGOT PASSWORD</span>
    </template>
    <template #body>
      <form class="flex gap-2 flex-col items-center mt-5 w-full">
        <AaidInput label="Your Email" v-model:value="username" type="email" aria-autocomplete="both" autocomplete="on" placeholder="name@example.com">
          <template #prependIcon>
              <MailIcon />
          </template>
        </AaidInput>
        <div class="flex flex-col w-full max-w-[360px]">
          <div class="bg-pink-500 mt-3 flex flex-col text-white p-3 rounded-lg shadow-xl" v-for="(item, ind) in errors"
            :key="ind">
            <div
              class="cursor-pointer flex self-end hover:bg-gray-400 hover:text-white font-bold w-fit border-bg-black rounded-full border-2 px-1.5"
              @click="closeErrorMsg(ind)">X</div>
            <span>{{ item }}</span>
          </div>
        </div>
        <button @click="handleReset" class="aaid_button max-w-[170px] flex gap-4 items-center" type="submit">Reset
          <Spinner v-if="isLoading" />
        </button>
      </form>
      <div class="flex flex-col mt-5 justify-center">
        <!-- <span class="font-bold">OR LOGIN WITH:</span>
        <div class="flex w-full justify-around mt-3 mb-10">
          <span @click="loginWithProvider('google')"
            class="hover:border-gray-900 hover:shadow-2xl shadow-lg rounded-lg p-4 border-2 border-white cursor-pointer text-black fa fa-google"></span>
          <span @click="loginWithProvider('facebook')"
            class="hover:border-gray-900 hover:shadow-2xl shadow-lg rounded-lg p-4 border-2 border-white cursor-pointer text-black fa fa-facebook"></span>
          <span @click="loginWithProvider('linkedin')"
            class="hover:border-gray-900 hover:shadow-2xl shadow-lg rounded-lg p-4 border-2 border-white cursor-pointer text-black fa fa-linkedin"></span>
        </div> -->
      </div>
    </template>
    <template #footer>
      <div class="flex justify-around">
        <span @click="showRegisterModal" class="cursor-pointer text-blue-400">Register</span>
        <span @click="showLoginModal" class="cursor-pointer text-blue-400">Login?</span>
      </div>
    </template>
  </Popup>
</template>
<style>
#login-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 300px;
  margin: auto;
  padding: 20px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  background-color: #fff;
}

#login-container h1 {
  color: #333;
}

#login-container button {
  padding: 10px;
  margin: 5px 0;
  width: 100%;
  box-sizing: border-box;
  font-size: 10px
}

#login-container input {
  padding: 10px;
  margin: 5px 0;
  width: 100%;
  box-sizing: border-box;
}
</style>
