<script setup lang="ts">
interface IProps {
    width?: number
    height?: number
    color?:string
    darkColor?:string
}
const props = defineProps<IProps>()
</script>
<template>
    <svg :class="[props.width ? props.width : 'w-4',props.height ? props.height : 'h-4', props.color ? props.color : 'text-gray-500', props.darkColor ? props.darkColor : 'text-gray-400']" aria-hidden="true" xmlns="http://www.w3.org/2000/svg"
        fill="none" stroke="currentColor"  viewBox="0 0 24 24">
        <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2"
                d="M12 15v2m-6 4h12a2 2 0 002-2v-6a2 2 0 00-2-2H6a2 2 0 00-2 2v6a2 2 0 002 2zm10-10V7a4 4 0 00-8 0v4h8z" />
    </svg>
</template>