<script setup lang="ts">
import { ref } from 'vue'
import Popup from './aaid/Popup.vue';
import useLoginStore from '../stores/useLoginStore';
import RegisterService from '../shared/services/RegisterService'
import LoginService from '../shared/services/LoginService'
import { AxiosResponse } from 'axios';
import { event } from 'vue-gtag'
import Spinner from './aaid/Spinner.vue'
import useToastMessageStore from '../stores/useToastMessageStore';
import AaidInput from './aaid/AaidInput.vue';
import MailIcon from './aaid/svgIcons/MailIcon.vue';
import LockIcon from './aaid/svgIcons/LockIcon.vue';
import ShowEyeIcon from './aaid/svgIcons/ShowEyeIcon.vue';
import HideEyeIcon from './aaid/svgIcons/HideEyeIcon.vue';

const usernameExists = ref(false)
const subscribe = ref(true)
const username = ref('')
const password = ref('')
const firstName = ref('')
const lastName = ref('')
const repeatPassword = ref('')
const isLoading = ref(false)
const showPassword = ref(false)
const showPassword1 = ref(false)
const errors: any = ref([])
const loginStore = useLoginStore()
const toastMessageStore = useToastMessageStore()
const onModalClose = () => {
  loginStore.setShowRegisterModal(false)
}
const registerService = RegisterService.getInstance()
const loginService = LoginService.getInstance()
const handleRegister = () => {
  isLoading.value = true
  if (repeatPassword.value === password.value) {
    registerService.post({ email: username.value, password: password.value, firstName: firstName.value, lastName: lastName.value, subscribe: subscribe.value }).then(async (regResp: AxiosResponse) => {
      if (regResp) {
        event('sign_up', { method: 'Email' })
        isLoading.value = false
        toastMessageStore.setMessage('Sucesfully registered, please verify your email')
        toastMessageStore.setTitle('Verify email')
        toastMessageStore.setType('success')
        toastMessageStore.setVisible(true)
        loginStore.setShowRegisterModal(false)
        loginStore.setShowLoginModal(false)
        loginStore.setShowForgotPassModal(false)
      }
    }).catch(e => {
      if (e.response.data.detail) {
        errors.value.push(e.response.data.detail)
      } else if (e.response.data.violations) {
        const newErrors = e.response.data.violations.map((z: any) => `${z.field} ${z.message}`)
        errors.value = newErrors
      }
      initClearErrors()
      isLoading.value = false
    })
  } else {
    errors.value.push('Passwords did not match')
    initClearErrors()
    isLoading.value = false
  }
}
// const loginWithProvider = (provider: string) => {
//   // console.log(provider)
//   //todo
// }
const checkUsername = () => {
  loginService.checkUsername(username.value).then((resp: AxiosResponse) => {
    usernameExists.value = resp.data
    if (resp && resp.data) {
      errors.value.push('The username already exists')
      initClearErrors()
    }
  }).catch(e => {
    if (e.response.data.detail) {
      errors.value.push(e.response.data.detail)
    } else if (e.response.data.violations) {
      const newErrors = e.response.data.violations.map((z: any) => `${z.field} ${z.message}`)
      errors.value = newErrors
    }
    initClearErrors()
  })
}
const closeErrorMsg = (ind: any) => {
  errors.value.splice(ind, 1)
}
const initClearErrors = () => {
  setTimeout(() => {
    errors.value = []
  }, 5000)
}
</script>
<template>
  <Popup v-if="loginStore.getShowRegisterModal" @on-close="onModalClose">
    <template #header>
      <div class="flex w-full justify-center">
        <img src="/logo.webp" alt="logo" width="192px" />
      </div>
    </template>
    <template #body>
      <form class="flex gap-2 flex-col items-center mt-5 w-full">
        <div class="flex gap-2 w-full">
          <div class="flex flex-col basis-full">
            <div class="relative mb-2 w-full">
              <AaidInput label="First Name" v-model:value="firstName" type="text" aria-autocomplete="both"
                autocomplete="on" placeholder="John" />
            </div>
          </div>
          <div class="flex flex-col basis-full">
            <div class="relative mb-2 w-full">
              <AaidInput label="Last Name" v-model:value="lastName" type="text" aria-autocomplete="both"
                autocomplete="on" placeholder="Doe" />
            </div>
          </div>
        </div>
        <AaidInput label="Your Email" v-model:value="username" type="text" @blur="checkUsername"
          :class="{ 'border-pink-500 ': usernameExists }" aria-autocomplete="both" autocomplete="on"
          placeholder="name@example.com">
          <template #prependIcon>
            <MailIcon :color="usernameExists ? 'text-pink-500' : ''" />
          </template>
        </AaidInput>
        <AaidInput label="Your Password" v-model:value="password" :type="showPassword1 ? 'text' : 'password'"
          aria-autocomplete="both" autocomplete="on">
          <template #prependIcon>
            <LockIcon />
          </template>
          <template #appendIcon>
            <ShowEyeIcon v-if="!showPassword1" @click="showPassword1 = !showPassword1" />
            <HideEyeIcon v-else @click="showPassword1 = !showPassword1" />
          </template>
        </AaidInput>
        <AaidInput label="Repeat Password" v-model:value="repeatPassword" :type="showPassword ? 'text' : 'password'"
          aria-autocomplete="both" autocomplete="on">
          <template #prependIcon>
            <LockIcon />
          </template>
          <template #appendIcon>
            <ShowEyeIcon v-if="!showPassword" @click="showPassword = !showPassword1" />
            <HideEyeIcon v-else @click="showPassword = !showPassword" />
          </template>
        </AaidInput>
        <div class="flex flex-col w-full max-w-[360px]">
          <div class="bg-pink-500 mt-3 flex flex-col text-white p-3 rounded-lg shadow-xl" v-for="(item, ind) in errors"
            :key="ind">
            <div
              class="cursor-pointer flex self-end hover:bg-gray-400 hover:text-white font-bold w-fit border-bg-black rounded-full border-2 px-1.5"
              @click="closeErrorMsg(ind)">X</div>
            <span>{{ item }}</span>
          </div>
        </div>
        <div class="flex items-center mb-4">
          <input id="default-checkbox" type="checkbox" v-model="subscribe"
            class="w-4 h-4 text-blue-600 dark:text-gray-400 bg-gray-100 border-gray-300 rounded focus:ring-blue-500 dark:focus:ring-blue-600 dark:ring-offset-gray-800 focus:ring-2 dark:bg-gray-700 dark:border-gray-600">
          <label for="default-checkbox" class="ms-2 text-sm font-medium text-gray-900 dark:text-gray-300">Uncheck this
            box if
            you prefer not to receive our email updates. Unless you opt out, you will be subscribed by default.</label>
        </div>
        <button :disabled="usernameExists" @click.prevent="handleRegister"
          class="aaid_button max-w-[170px] flex gap-4 items-center" type="submit">Register
          <Spinner v-if="isLoading" />
        </button>
      </form>
      <!-- <div class="flex flex-col mt-5 justify-center">
        <span class="font-bold">OR LOGIN WITH:</span>
        <div class="flex w-full justify-around mt-3 mb-10">
          <span @click="loginWithProvider('google')"
            class="hover:border-gray-900 hover:shadow-2xl shadow-lg rounded-lg p-4 border-2 border-white cursor-pointer text-black fa fa-google"></span>
          <span @click="loginWithProvider('facebook')"
            class="hover:border-gray-900 hover:shadow-2xl shadow-lg rounded-lg p-4 border-2 border-white cursor-pointer text-black fa fa-facebook"></span>
          <span @click="loginWithProvider('linkedin')"
            class="hover:border-gray-900 hover:shadow-2xl shadow-lg rounded-lg p-4 border-2 border-white cursor-pointer text-black fa fa-linkedin"></span>
        </div>
      </div> -->
    </template>
  </Popup>
</template>
<style>
#login-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 300px;
  margin: auto;
  padding: 20px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  background-color: #fff;
}

#login-container h1 {
  color: #333;
}

#login-container button {
  padding: 10px;
  margin: 5px 0;
  width: 100%;
  box-sizing: border-box;
  font-size: 10px
}

#login-container input {
  padding: 10px;
  margin: 5px 0;
  width: 100%;
  box-sizing: border-box;
}
</style>
