
export const isMobile = () => {
    // @ts-ignore
    const userAgent = navigator.userAgent || navigator.vendor || window.opera;
    const mobilePatterns = /Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i;
    return mobilePatterns.test(userAgent);
}
import hpcp from '../assets/hpcp.webp'
import soundWaves from '../assets/sound-waves.webp'
import tapper from '../assets/tapper.webp'
import metronome from '../assets/metronome.webp'
import Home from '../assets/house-shadow.webp'
import audioTrimmer from '../assets/trim-audio.webp'
import mic from '../assets/mic.webp'
export const MainMenu = [{
    name: 'HOME',
    icon: Home,
    link: '/'
  }, {
    name: 'MUSIC ANALYZER',
    icon: soundWaves,
    link: 'music-analyzer'
  }, {
    name: 'BPM TAPPER',
    icon: tapper,
    link: 'bpm-tapper'
  }, {
    name: 'AUDIO TRIMMER',
    icon: audioTrimmer,
    link: 'audio-trimmer'
  }, {
    name: 'VOICE RECORDER',
    icon: mic,
    link: 'voice-recorder'
  }, {
    name: 'HPCP CHROMA',
    icon: hpcp,
    link: 'hpcp-chroma'
  }, {
    name: 'ONLINE METRONOME',
    icon: metronome,
    link: 'online-metronome'
  }]

  export const parseJwt = (token: string) => {
    var base64Url = token.split('.')[1];
    var base64 = base64Url.replace(/-/g, '+').replace(/_/g, '/');
    var jsonPayload = decodeURIComponent(window.atob(base64).split('').map(function(c) {
        return '%' + ('00' + c.charCodeAt(0).toString(16)).slice(-2);
    }).join(''));

    return JSON.parse(jsonPayload);
}

export const subscribeUser = (user: any, subscribeService: any) => {
  const dto = {
    firstName: user.firstName,
    lastName: user.lastName,
    email: user.email,
  }
  subscribeService.post(dto)
}

export const generateRandomString = (length: any) => {
  const possible = 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789';
  const values = crypto.getRandomValues(new Uint8Array(length));
  return values.reduce((acc, x) => acc + possible[x % possible.length], "");
}
export const sha256 = async (plain: any) => {
  const encoder = new TextEncoder()
  const data = encoder.encode(plain)
  return window.crypto.subtle.digest('SHA-256', data)
}
export const base64encode = (input: any) => {
  return btoa(String.fromCharCode(...new Uint8Array(input)))
    .replace(/=/g, '')
    .replace(/\+/g, '-')
    .replace(/\//g, '_');
}
