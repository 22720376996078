<script setup lang="ts">
import { ref } from "vue";
import { isMobile, MainMenu } from "../../shared/helpers";
import { useRoute, useRouter } from "vue-router";
import useLoginStore from "../../stores/useLoginStore";
import useUserStore from "../../stores/useUserStore";
import { eraseCookie } from "../../shared/helpers/cookieHelpers";
import UserMenu from "../user/UserMenu.vue";
const route = useRoute();
const router = useRouter()
const toggleMenu = ref(false);
const toggleHamburger = () => {
  const tham: any = document.querySelector(".tham");
  tham.classList.toggle("tham-active");
  toggleMenu.value = !toggleMenu.value;
};
const loginStore = useLoginStore();
const userStore = useUserStore();

const logout = () => {
  userStore.setAuthenticated(false);
  if (route.meta && route.meta.requiresAuth) {
    router.push({ name: 'home' })
  }
  eraseCookie("access_token");
  eraseCookie("refresh_token");
};

const showLogin = () => {
  loginStore.setShowLoginModal(true);
  loginStore.setShowRegisterModal(false);
  loginStore.setShowForgotPassModal(false);
};
</script>

<template>
  <header class="flex flex-row gap-20 w-full items-center" :class="{
    'justify-between': isMobile(),
    'max-w-[1560px] mx-auto': !isMobile(),
  }">
    <div class="logo p-6">
      <router-link to="/">
        <img src="/logo.webp" style="height: 40px" alt="logo" class="max-h-[80px]" />
      </router-link>
    </div>
    <nav class="fixed p-6 top-1 bottom-0 left-0 z-10 w-[320px] bg-white text-gray-800 rounded-lg shadow-lg"
      v-if="toggleMenu">
      <ul class="relative z-30 p-10 gap-5 flex flex-col">
        <li v-for="(item, ind) in MainMenu" :key="ind" @click="toggleHamburger">
          <RouterLink :to="item.link" class="hover:text-[#230FBF]">{{ item.name }}
          </RouterLink>
        </li>
        <li @click="toggleHamburger" v-if="userStore.getAuthenticated">
          <router-link to="/profile">
            <span>PROFILE</span>
          </router-link>
        </li>
        <li @click="toggleHamburger" v-if="userStore.getAuthenticated">
          <router-link to="/settings">
            <span>SETTINGS</span>
          </router-link>
        </li>
        <li @click="toggleHamburger">
          <span @click="showLogin" v-if="!userStore.getAuthenticated"> LOGIN </span>
          <span @click="logout" v-else> LOGOUT </span>
        </li>
      </ul>
    </nav>
    <div v-if="isMobile()" class="fixed z-30 top-0 p-4 right-0">
      <div class="tham tham-e-squeeze tham-w-6" @click="toggleHamburger">
        <div class="tham-box z-40">
          <div class="tham-inner" />
        </div>
      </div>
    </div>
    <nav v-else
      class="flex items-center text-center justify-between p-6 flex-col md:flex-row md:gap-10 max-sm:hidden">
      <RouterLink :to="item.link" v-for="(item, ind) in MainMenu" :key="ind" class="hover:text-[#230FBF] text-sm">{{ item.name
        }}
      </RouterLink>
      <div class="flex items-center cursor-pointer">
        <UserMenu :show-carret="true" v-if="userStore.getAuthenticated" />
        <span @click="showLogin" class="font-bold hover:text-[#230FBF]" v-else>LOGIN / REGISTER</span>
      </div>
    </nav>
  </header>
</template>

<style scoped></style>
