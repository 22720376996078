<script setup lang="ts">
import Footer from '../components/aaid/Footer.vue'
import SideMenuVue from '../components/aaid/SideMenu.vue';
import { router } from '../router';
import { isMobile, MainMenu } from '../shared/helpers'
import { ref, onMounted, onUnmounted } from 'vue';
import { eraseCookie } from '../shared/helpers/cookieHelpers';
import useLoginStore from '../stores/useLoginStore';
import useUserStore from '../stores/useUserStore';
import { useRoute } from 'vue-router';

const isAtBottom = ref(false);
const toggleMenu = ref(false);
const route = useRoute()
const toggleHamburger = () => {
    const tham: any = document.querySelector(".tham1");
    tham.classList.toggle("tham-active");
    toggleMenu.value = !toggleMenu.value;
};
const checkScrollPosition = () => {
    const documentHeight = document.documentElement.scrollHeight;
    const windowHeight = window.innerHeight;
    const scrollY = window.scrollY;

    isAtBottom.value = (windowHeight + scrollY) >= documentHeight;
};
const loginStore = useLoginStore();
const userStore = useUserStore();

const logout = () => {
  userStore.setAuthenticated(false);
  if (route.meta && route.meta.requiresAuth) {
    router.push({ name: 'home' })
  }
  eraseCookie("access_token");
  eraseCookie("refresh_token");
};

const showLogin = () => {
  loginStore.setShowLoginModal(true);
  loginStore.setShowRegisterModal(false);
  loginStore.setShowForgotPassModal(false);
};
onMounted(() => {
    window.addEventListener('scroll', checkScrollPosition);
    checkScrollPosition();
});

onUnmounted(() => {
    window.removeEventListener('scroll', checkScrollPosition);
});

</script>
<template>
    <div class="w-full">
        <div v-if="isMobile()" class="fixed z-30 top-0 p-4 right-0">
            <div class="tham1 tham-e-squeeze tham-w-6" @click="toggleHamburger">
                <div class="tham-box z-40">
                    <div class="tham-inner" />
                </div>
            </div>
        </div>
        <SideMenuVue v-else />
        <div class="fixed p-6 top-1 bottom-0 left-0 z-10 w-[320px] bg-white text-gray-800 rounded-lg shadow-lg"
            v-if="toggleMenu">
            <ul class="relative z-30 p-10 gap-5 flex flex-col">
                <li v-for="(item, ind) in MainMenu" :key="ind" @click.prevent="toggleHamburger">
                    <RouterLink :to="item.link" class="hover:text-[#230FBF]">{{ item.name }}
                    </RouterLink>
                </li>
                <li @click="toggleHamburger" v-if="userStore.getAuthenticated">
                    <router-link to="/profile">
                        <span>PROFILE</span>
                    </router-link>
                </li>
                <li @click="toggleHamburger" v-if="userStore.getAuthenticated">
                    <router-link to="/settings">
                        <span>SETTINGS</span>
                    </router-link>
                </li>
                <li @click="toggleHamburger">
                    <span @click="showLogin" v-if="!userStore.getAuthenticated"> LOGIN </span>
                    <span @click="logout" v-else> LOGOUT </span>
                </li>
            </ul>
        </div>
        <div :class="isMobile() ? 'p-4 text-center' : 'ps-32'"
            class=" flex flex-col items-center justify-around min-h-screen relative">
            <div class="mb-30 flex flex-col items-center w-full">
                <slot />
            </div>
            <div class="mt-auto w-full">
                <Footer />
            </div>
        </div>
    </div>
</template>