import axios from 'axios'
import type { AxiosResponse } from 'axios'
import { getCookie } from '../helpers/cookieHelpers'

export default abstract class BaseService<T> {
  protected url: string

  protected constructor(url: string) {
    this.url = url
  }
  protected apiClient = axios.create({
    baseURL: '/',
    transformRequest: [function (data, headers) {
      const accessToken = getCookie('access_token')
      const refreshToken = getCookie('refresh_token')

      if (accessToken)
        headers['Authorization'] = `Bearer ${accessToken}`
      else if (refreshToken && !accessToken)
        headers['Authorization'] = `Bearer ${refreshToken}`

      return JSON.stringify(data)
    }],
    headers: {
      "Content-Type": "application/json",
      "Accept": "application/json"
    }
  });
  public async getRequest(url: string, params?: any, config?:any): Promise<AxiosResponse> {
    let urlParams = ''
    if (params) {
      for (const key of Object.keys(params))
        urlParams += `${key}=${params[key]}&`
    }

    return new Promise<AxiosResponse>((resolve, reject) => {
      this.apiClient
        .get(`${url}${urlParams ? `?${urlParams}` : ''}`, config)
        .then((resp: AxiosResponse) => {
          resolve(resp)
        })
        .catch(e => {
          reject(e)
        })
    })
  }

  public async postRequest(url: string, obj: T, config?:any): Promise<AxiosResponse> {
    return new Promise<AxiosResponse>((resolve, reject) => {
      this.apiClient
        .post(url, obj, config)
        .then((resp: AxiosResponse) => {
          resolve(resp)
        })
        .catch(e => {
          reject(e)
        })
    })
  }

  public async patchRequest(url: string, obj: T): Promise<AxiosResponse> {
    return new Promise<AxiosResponse>((resolve, reject) => {
      this.apiClient
        .patch(url, obj)
        .then((resp: AxiosResponse) => {
          resolve(resp)
        })
        .catch(e => {
          reject(e)
        })
    })
  }

  public async putRequest(url: string, obj: T): Promise<AxiosResponse> {
    return new Promise<AxiosResponse>((resolve, reject) => {
      this.apiClient
        .put(url, obj)
        .then((resp: AxiosResponse) => {
          resolve(resp)
        })
        .catch(e => {
          reject(e)
        })
    })
  }

  public async deleteRequest(url: string): Promise<AxiosResponse> {
    return new Promise<AxiosResponse>((resolve, reject) => {
      this.apiClient
        .delete(url)
        .then((resp: AxiosResponse) => {
          resolve(resp)
        })
        .catch(e => {
          reject(e)
        })
    })
  }
}
