<script setup lang="ts">
import { ref, watch } from 'vue';

interface IProps {
    label?: string
    value?: any
    align?: string
}
const id: any = Math.random()
const props = defineProps<IProps>()
const localValue = ref('')
const emits = defineEmits(['update:value'])
const emitChange = (e: any) => {
    e.preventDefault()
    emits('update:value', localValue.value)
}
watch(() => props.value, (newVal: any) => {
    if(newVal) {
        localValue.value = newVal
    }
}, {immediate: true, deep: true})
</script>
<template>
    <div class="flex flex-col w-full gap-2" :class="props.align || 'items-center'">
        <label :for="$attrs.id || id" class="block text-sm font-bold text-gray-700">{{ props.label }}</label>
        <div class="relative mb-6 w-full">
            <div class="absolute inset-y-0 start-0 flex items-center ps-3.5 pointer-events-none" v-if="$slots.prependIcon">
                <slot name="prependIcon"/>
            </div>
          <input v-bind="$attrs" :id="$attrs.id || id" v-model="localValue" @input="emitChange"
            v-if="$attrs.type !== 'textarea'"
            :class="{'ps-10': $slots.prependIcon}"
            class="bg-gray-50 dark:text-gray-400 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5  dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:focus:ring-blue-500 dark:focus:border-blue-500"/>
            <textarea v-else v-bind="$attrs" v-model="localValue" @input="emitChange" :class="{'ps-10': $slots.prependIcon}"
            class="bg-gray-50 dark:text-gray-400 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5  dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:focus:ring-blue-500 dark:focus:border-blue-500"></textarea>
            <div v-if="$slots.appendIcon" class="absolute inset-y-0 end-0 flex items-center pe-3.5 cursor-pointer select-none">
                <slot name="appendIcon"/>
            </div>
        </div>
    </div>
</template>