<script setup lang="ts">
import useToastMessageStore from '../../stores/useToastMessageStore';

const toastMessageStore = useToastMessageStore()
const getBgColor = () => {
    switch (toastMessageStore.getMessageType) {
        case 'success':
            return 'bg-green-500'
        case 'alert':
            return 'bg-pink-500'
        case 'info':
            return 'bg-blue-500'
        case 'warning':
            return 'bg-orange-500'

    }
}
const closeErrorMsg = () => {
    toastMessageStore.setVisible(false)
    toastMessageStore.setMessage('')
    toastMessageStore.setTitle('')
}
</script>
<template>
    <div v-if="toastMessageStore.getVisible" class="flex flex-col w-full lg:max-w-[360px] sm:max-w-[320px] fixed lg:top-70 lg:right-10 sm:right-0 sm:top-4 z-40">
        <div :class="getBgColor()" class=" mt-3 flex flex-col text-white p-3 rounded-lg shadow-xl">
            <div class="cursor-pointer flex self-end hover:bg-gray-400 hover:text-white font-bold w-fit border-bg-black rounded-full border-2 px-1.5"
                @click="closeErrorMsg">X</div>
            <span v-if="toastMessageStore.getTitle" class="text-bold text-lg">{{ toastMessageStore.getTitle }}</span>
            <span v-if="toastMessageStore.getMessage" class="text-sm">{{ toastMessageStore.getMessage }}</span>
        </div>
    </div>
</template>