<script setup lang="ts">
import { ref } from 'vue';
import { useRoute, useRouter } from "vue-router";
import Avatar from './Avatar.vue';
import useUserStore from '../../stores/useUserStore';
import User from '../../shared/models/UserModel';
import { eraseCookie } from '../../shared/helpers/cookieHelpers';
interface IProps {
    showCarret?: boolean
}
const route = useRoute();
const router = useRouter()
const props = defineProps<IProps>()
const toggleMenu = ref(false)
const userStore = useUserStore()
const logout = () => {
    userStore.setUser(new User('', ''))
    userStore.setAuthenticated(false)
    if (route.meta && route.meta.requiresAuth) {
        router.push({ name: 'home' })
    }
    eraseCookie('access_token')
    eraseCookie('refresh_token')
}
</script>
<template>
    <div class="flex items-center justify-center relative" @mouseenter="toggleMenu = true"
        @mouseleave="toggleMenu = false">
        <div class="flex items-center relative">
            <Avatar :size="30" />
            <svg v-if="!toggleMenu && props.showCarret" class="h-6 w-6 text-gray-500" width="24" height="24"
                viewBox="0 0 24 24" stroke-width="2" stroke="currentColor" fill="none" stroke-linecap="round"
                stroke-linejoin="round">
                <path stroke="none" d="M0 0h24v24H0z" />
                <path d="M18 15l-6-6l-6 6h12" transform="rotate(180 12 12)" />
            </svg>
            <svg v-else-if="props.showCarret" class="h-6 w-6 text-gray-500" width="24" height="24" viewBox="0 0 24 24"
                stroke-width="2" stroke="currentColor" fill="none" stroke-linecap="round" stroke-linejoin="round">
                <path stroke="none" d="M0 0h24v24H0z" />
                <path d="M18 15l-6-6l-6 6h12" />
            </svg>
        </div>
        <div v-if="toggleMenu"
            class="absolute bg-white flex flex-col items-center justify-center p-4 border-1 rounded-lg shadow-2xl">
            <ul>
                <li class="cursor-pointer p-3 text-blue-500 hover:underline">
                    <RouterLink to="/profile">Profile</RouterLink>
                </li>
                <li class="cursor-pointer p-3 text-blue-500 hover:underline">
                    <router-link to="/settings">
                        <span>Settings</span>
                    </router-link>
                </li>
                <li @click="logout" class="cursor-pointer p-3 text-blue-500 hover:underline">Logout</li>
            </ul>
        </div>
    </div>
</template>