import { createApp } from 'vue'
import './style.css'
import { createI18n } from 'vue-i18n'
import App from './App.vue'
import { router } from './router'
import en from './translations/en.json'
import es from './translations/es.json'
import VueGtag from "vue-gtag";
import { pinia } from './stores/index'
import './shared/helpers/interceptors'


const messages = {
    en: en,
    es: es
}
const i18n = createI18n({
    locale: 'en',
    fallbackLocale: 'en',
    messages,
})

const app = createApp(App)
app.use(router)
app.use(i18n)

app.use(pinia)
app.use(VueGtag, {
    bootstrap: false,
    appName: 'Audio AI Dyanmics',
    pageTrackerScreenviewEnabled: true,
    config: { id: "G-760QLTDMM5" }
}, router)
app.mount('#app')