export const generateSocialLink = (webLink: string, iosLink: string, androidLink: string) => {
    //@ts-ignore
  const userAgent = navigator.userAgent || navigator.vendor || window.opera;
  //@ts-ignore
  const isIOS = /iPad|iPhone|iPod/.test(userAgent) && !window.MSStream;
  const isAndroid = /android/i.test(userAgent);
  if (isIOS) {
    return iosLink
  } else if (isAndroid) {
     return androidLink
  } else {
    return webLink;
  }
};
