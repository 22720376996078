import { defineStore } from 'pinia'
import type { IUser } from '../shared/models/UserModel'

interface UserStoreInterface {
  user: IUser | null
  authenticated: boolean
  language: string
}

const useUserStore = defineStore({
  id: 'useUserStore',
  state: () =>
    ({
      user: null,
      authenticated: false,
      language: navigator.language,
    } as UserStoreInterface),
  getters: {
    getUser: state => state.user,
    getAuthenticated: state => state.authenticated,
    getLanguage: state => state.language,
    getAvatar: state => state.user?.avatarImage,
    getAvatarUrl: state => state.user?.avatarUrl,
  },
  actions: {
    setUser(user: IUser) {
      this.user = user
    },
    setAuthenticated(authenticated: boolean) {
      this.authenticated = authenticated
    },
  },
})

export default useUserStore
