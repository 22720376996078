import { defineStore } from "pinia"

interface LoginStoreInterface {
  showLoginModal: boolean
  showRegisterModal: boolean
  showForgotPassModal: boolean
  user: any
}

const useLoginStore = defineStore({
  id: 'useLoginStore',
  state: () =>
    ({
      showLoginModal: false,
      showRegisterModal: false,
      showForgotPassModal: false
    } as LoginStoreInterface),
  getters: {
    getShowLoginModal: state => state.showLoginModal,
    getShowRegisterModal: state => state.showRegisterModal,
    getShowForgotPassModal: state => state.showForgotPassModal,
  },
  actions: {
    setShowLoginModal(results: boolean) {
      this.showLoginModal = results
    },
    setShowForgotPassModal(results: boolean) {
      this.showForgotPassModal = results
    },
    setShowRegisterModal(results: boolean) {
      this.showRegisterModal = results
    },
  },
})

export default useLoginStore
