<script setup lang="ts">
import { onMounted, onUnmounted } from 'vue';

const scrollFunction = () => {
  const mybutton: any = document.getElementById("btn-back-to-top");
  if (
    document.body.scrollTop > 20 ||
    document.documentElement.scrollTop > 20
  ) {
    mybutton.classList.remove("hidden");
  } else {
    mybutton.classList.add("hidden");
  }
};
const backToTop = () => {
  window.scrollTo({ top: 0, behavior: "smooth" });
};
onMounted(() => {
  window.addEventListener("scroll", scrollFunction);
})
onUnmounted(() => {
  window.removeEventListener("scroll", scrollFunction);
})
</script>
<template>
  <button @click="backToTop" type="button" data-twe-ripple-init data-twe-ripple-color="light"
    class="!fixed bottom-5 end-5 hidden rounded-full text-center justify-center flex aaid_button_circle px-0 mx-0 p-3 text-xs font-medium uppercase leading-tight text-white shadow-md transition duration-150 ease-in-out hover:shadow-lg focus:shadow-lg focus:outline-none focus:ring-0 active:shadow-lg"
    id="btn-back-to-top">
    <span class="[&>svg]:w-4">
      <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="3" stroke="currentColor">
        <path stroke-linecap="round" stroke-linejoin="round" d="M4.5 10.5 12 3m0 0 7.5 7.5M12 3v18" />
      </svg>
    </span>
  </button>
</template>