<template>
    <div class="flex flex-col min-h-screen relative w-full">
        <Header />
        <div class="mb-30 flex flex-col items-center w-full">
                <slot />
            </div>
        <div class="mt-auto w-full">
            <Footer />
        </div>
    </div>
</template>

<script setup lang="ts">
import Header from '../components/aaid/Header.vue'
import Footer from '../components/aaid/Footer.vue'
import { ref, onMounted, onUnmounted } from 'vue';

const isAtBottom = ref(false);

const checkScrollPosition = () => {
  const documentHeight = document.documentElement.scrollHeight;
  const windowHeight = window.innerHeight;
  const scrollY = window.scrollY;

  isAtBottom.value = (windowHeight + scrollY) >= documentHeight;
};

onMounted(() => {
  window.addEventListener('scroll', checkScrollPosition);
  checkScrollPosition();
});

onUnmounted(() => {
  window.removeEventListener('scroll', checkScrollPosition);
});
</script>

<style scoped></style>