import { defineStore } from 'pinia'

interface ToastMessageInterface {
  title?: string
  message?: string
  messageType?: string // successs, info, warning, error
  timeout: number
  visible: boolean
}

const useToastMessageStore = defineStore({
  id: 'useToastMessageStore',
  state: () =>
    ({
      title: '',
      message: '',
      messageType: '',
      timeout: 6000,
      visible: false,
    } as ToastMessageInterface),
  getters: {
    getTitle: state => state.title,
    getMessage: state => state.message,
    getTimeout: state => state.timeout,
    getVisible: state => state.visible,
    getMessageType: state => state.messageType,
  },
  actions: {
    setTitle(title: string) {
      this.title = title
    },
    setMessage(message: string) {
      this.message = message
    },
    setType(type: string) {
      this.messageType = type
    },
    setVisible(visible: boolean) {
      this.visible = visible
      if(visible) {
        setTimeout(()=> {
          this.setMessage('')
          this.setTitle('')
          this.setVisible(false)
        }, this.timeout)
      }
    },
  },
})

export default useToastMessageStore
