<script setup lang="ts">
interface IProps {
    width?: number
    height?: number
    color?:string
    darkColor?:string
}
const props = defineProps<IProps>()
</script>
<template>
    <svg :class="[props.width ? props.width : 'w-4',props.height ? props.height : 'h-4', props.color ? props.color : 'text-gray-500', props.darkColor ? props.darkColor : 'text-gray-400']" aria-hidden="true" xmlns="http://www.w3.org/2000/svg"
        fill="none"  stroke="currentColor" viewBox="0 0 24 24">
        <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2"
                d="M15 12a3 3 0 11-6 0 3 3 0 016 0z" />
              <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2"
                d="M2.458 12C3.732 7.943 7.523 5 12 5c4.478 0 8.268 2.943 9.542 7-1.274 4.057-5.064 7-9.542 7-4.477 0-8.268-2.943-9.542-7z" />
    </svg>
</template>