import { ref } from "vue";
import SocialLoginService from "../shared/services/SocialLoginService";
import { AxiosResponse } from "axios";

import useToastMessageStore from "../stores/useToastMessageStore";
import useLoginStore from "../stores/useLoginStore";
import { setCookie } from "../shared/helpers/cookieHelpers";
import useUserStore from "../stores/useUserStore";
import MeService from "../shared/services/MeService";
export function useGoogleLogin() {
  const clientId = ref(
    "128925820014-su0ll47raiu26k7k2t1jqqnof6b0vg59.apps.googleusercontent.com",
  );

  const initializeGoogleSignIn = () => {
    // @ts-ignore
    window.google.accounts.id.initialize({
      client_id: clientId.value,
      callback: handleCredentialResponse,
    });

    const buttonDiv = document.getElementById("googleLogin");
    if (buttonDiv) {
      // @ts-ignore
      window.google.accounts.id.renderButton(buttonDiv, {
        theme: "outline",
        size: "large",
        text: "Login With",
        shape: "circular",
        type: "standard",
      });
      // @ts-ignore
      window.google.accounts.id.prompt();
    } else {
      console.error("Button div not found");
    }
  };

  function handleCredentialResponse(response: any) {
    const toastMessageStore = useToastMessageStore();
    const socialLoginService = SocialLoginService.getInstance();
    const meService = MeService.getInstance()
    const userStore = useUserStore()

    const loginStore = useLoginStore();
    socialLoginService
      .post({ value: response.credential })
      .then(async (resp: AxiosResponse) => {
        if (resp && resp.data) {
          setCookie(
            "access_token",
            resp.data.accessToken,
            resp.data.accessTokenExpiresIn / 86400,
          );
          setCookie(
            "refresh_token",
            resp.data.refreshToken,
            resp.data.refreshTokenExpiresIn / 86400,
          );
          const token = resp.data.accessToken;
          if (token) {
            const user = await meService.get();
            if (user && user.data) {
              userStore.setAuthenticated(true);
              userStore.setUser(user.data);
            }
          }
          toastMessageStore.setMessage("Sucesfully Logged in");
          toastMessageStore.setTitle("Success");
          toastMessageStore.setType("success");
          toastMessageStore.setVisible(true);
          loginStore.setShowRegisterModal(false);
          loginStore.setShowLoginModal(false);
          loginStore.setShowForgotPassModal(false);
        }
      })
      .catch((e: any) => {
        if (e.response.data.detail) {
          toastMessageStore.setMessage(e.response.data.detail);
        } else if (e.response.data.violations) {
          const newErrors = e.response.data.violations.map(
            (z: any) => `${z.field} ${z.message}`,
          );
          toastMessageStore.setMessage(newErrors);
        }
        toastMessageStore.setTitle("Error");
        toastMessageStore.setType("alert");
        toastMessageStore.setVisible(true);
      });
  }

  return { initializeGoogleSignIn };
}
