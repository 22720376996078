import axios from 'axios'
import { getCookie } from './cookieHelpers'

axios.interceptors.request.use(config => {
  const accessToken = getCookie('access_token')
  const refreshToken = getCookie('refresh_token')
  if (accessToken)
    config.headers.Authorization = `Bearer ${accessToken}`
  else if (refreshToken && !accessToken)
    config.headers.Authorization = `Bearer ${refreshToken}`

  return config
}, error => {
  return Promise.reject(error)
})
