import type { AxiosResponse } from 'axios'
import baseService from './baseService'

export default abstract class BaseEntityService<T> extends baseService<T> {
  public url: string
  protected constructor(url: string) {
    super(url)
    this.url = url
  }

  public async get(id?: number | string): Promise<AxiosResponse> {
    return super.getRequest(`${this.url}/${id ? id : ''}`)
  }

  public async getAll(pagination?: any, q?: any): Promise<AxiosResponse> {
    const pag = this.createPaginationQuery(pagination || null)
    const query = q || undefined
    if (query)
      return super.getRequest(`${this.url}?${pag}&${query}`)

    else
      return super.getRequest(`${this.url}?${pag}`)
  }

  public async post(entity: T): Promise<AxiosResponse> {
    return super.postRequest(this.url, entity)
  }

  public async patch(entity: T): Promise<AxiosResponse> {
    return super.patchRequest(this.url, entity)
  }

  public async put(entity: T): Promise<AxiosResponse> {
    return super.putRequest(`${this.url}`, entity)
  }

  public async delete(id?: number): Promise<AxiosResponse> {
    return super.deleteRequest(`${this.url}/${id || ''}`)
  }

  public createPaginationQuery(pagination: any) {
    return `page=${pagination.page}&size=${pagination.size}&sort=${pagination.sort}`
  }

  public createSearchQuery(query: any) {
    let urlParams = ''
    if (query) {
      for (const key of Object.keys(query))
        urlParams += `${key}=${query[key]}&`
    }

    return urlParams
  }
}
